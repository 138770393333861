<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-08 15:08:19
-->
<template>
  <div>
    <div class="content-title">
      <p>{{ contents.articleName }}</p>
      <p>{{ contents.summary }}</p>
    </div>
    <p class="hr"></p>

    <div class="contentView">

      <div class="content contentsy" v-html="contents.content">
      </div>
    </div>
  </div>
</template>
<script>
import vuescrolljs from '@/mixin/index.js'
// import ListTitle from "@/components/reuse/listTitle";
import "@/css/elememtUi.less";
import { articleDetail } from '@/api'
import dateFormat from '@/utils/time'
import '@/css/content.less'

export default {
  mixins: [vuescrolljs],
  data() {
    return {
      loading: false,
      dateFormat,
      headerNmaeCh: `<p style="color: #57802b;">中国房地产行业绿色采购指南</p>`,
      headerNmae:
        `<p style="color: #57802b;  ">Green Procurement Guide For China's Real Estate Industry</p>`,
      list: [],
      pages: {
        page: 0,
        limit: 10
      },
      total: null,
      flag: false,
      contents: {}
      // currentPage1: 1,
    };
  },
  mounted() {
    this.hub.$emit('tatleNames')
    this.hub.$emit('theCover', this.$route.meta.flagNam)

    this.hub.$emit('scrollToz')
    this.getApiList()
  },
  methods: {
    getApiList() {
      const params = {
        articleId: 'WZNR201610311925050025',
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleDetail(params).then((res) => {
        this.contents = res.data
      })
    },
    findS(id, index) {

      this.list = this.list.map((item, i) => {
        if (index == i) {
          item.flag = !item.flag
        }
        return item
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pages.page = val
      this.getApiList()
    }
  },
  components: {
    // ListTitle
  }
};
</script>
<style lang="less" scoped>
.hr {
  margin-top: 24px;
  margin-right: 22px;
  margin-bottom: 20px;
  border-top: 2px solid #8c8887;
}

.content {
  padding: 0 20px 30px 0;
}

.content-title {
  margin: 20px 0px;

  p:nth-child(1) {
    font-size: 30px;
    font-weight: 700;
    color: #92ab26;
  }

  p:nth-child(2) {
    width: 96%;
    font-size: 18px;
    color: #747374;
    padding: 10px 0px 10px 0;
    // margin-bottom: 10px;
    // border-bottom: 1px solid  #ccc;
  }
}

/deep/ .contentView img {
  max-width: 1020px !important;

}
</style>
